import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import { CalendarPage } from "./pages/calendar";
import { SettingsPage } from "./pages/settings";
import Home from "./pages/home";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="calendar" element={<CalendarPage />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>
    </Routes>
  );
};

export default App;
