import { useState } from "react";
import { Amplify } from "aws-amplify";
import {
  AmplifyProvider,
  Authenticator,
  Button,
  Image,
} from "@aws-amplify/ui-react";
import aws_exports from "../aws-exports";
import { Outlet, Link } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import theme from "../theme";
import { Avatar, Layout, Menu, Popover, Row } from "antd";
import {
  // CalendarOutlined,
  // FormOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ducklogo from "../duck-logo.png";

const { Header, Content, Sider } = Layout;
const { Item: MenuItem } = Menu;
Amplify.configure(aws_exports);

const MainLayout = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  return (
    <AmplifyProvider theme={theme}>
      <Authenticator>
        {({ signOut, user }) => (
          <Layout style={{ minHeight: "100vh" }}>
            <Header>
              <Row
                justify="space-between"
                align="middle"
                style={{ height: "100%" }}
              >
                <Row>
                  <Image
                    src={ducklogo}
                    alt="logo"
                    height={60}
                    style={{ marginRight: 8 }}
                  />
                  <span style={{ color: "white", fontSize: 18 }}>
                    Extra Medium Duck - Server 1136 - BAR
                  </span>
                </Row>

                <Popover
                  trigger={"click"}
                  content={
                    <div style={{ padding: 16 }}>
                      <Button
                        style={{ cursor: "pointer" }}
                        size="small"
                        onClick={signOut}
                      >
                        Sign out
                      </Button>
                    </div>
                  }
                >
                  <Avatar
                    size="large"
                    icon={<UserOutlined />}
                    style={{ backgroundColor: "#fde3cf", color: "#f56a00" }}
                  />
                </Popover>
              </Row>
            </Header>
            <Layout>
              <Sider
                collapsible
                onCollapse={(c) => setSiderCollapsed(c)}
                collapsed={siderCollapsed}
              >
                <Menu theme="dark">
                  <MenuItem key="index" icon={<HomeOutlined />}>
                    <Link to="/" key="index">
                      Home
                    </Link>
                  </MenuItem>
                  {/* <MenuItem key="calendar" icon={<CalendarOutlined />}>
                    <Link to="/calendar" key="calendar">
                      Calendar
                    </Link>
                  </MenuItem>
                  <MenuItem key="settings" icon={<FormOutlined />}>
                    <Link to="/settings" key="settings">
                      Settings
                    </Link>
                  </MenuItem> */}
                </Menu>
              </Sider>
              <Layout>
                <Content style={{ padding: 16, backgroundColor: "white" }}>
                  <Outlet context={{ signOut, user }} />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        )}
      </Authenticator>
    </AmplifyProvider>
  );
};

export default MainLayout;
