// import { BorderVerticleOutlined } from "@ant-design/icons";
import {
  //Avatar,
  Col,
  //List,
  Row,
  //Table,
  // Typography,
} from "antd";
import Clock from "react-live-clock";

// const { Title } = Typography;

// const listData = [
//   {
//     title: "Title 1",
//   },
//   {
//     title: "Title 2",
//   },
//   {
//     title: "Title 3",
//   },
//   {
//     title: "Title 4",
//   },
// ];

// const dataSource = [
//   {
//     key: "1",
//     name: "Mike",
//     bubbleAdded: Date.now,
//     bubbleExpiry: Date.now,
//   },
//   {
//     key: "2",
//     name: "John",
//     bubbleAdded: Date.now,
//     bubbleExpiry: Date.now,
//   },
// ];

// const columns = [
//   {
//     title: "Name",
//     dataIndex: "name",
//     key: "name",
//   },
//   {
//     title: "Bubble Added",
//     dataIndex: "bubbleAdded",
//     key: "bubbleAdded",
//   },
//   {
//     title: "Bubble Expiry",
//     dataIndex: "bubbleExpiry",
//     key: "bubbleExpiry",
//   },
// ];

const Home = () => {
  return (
    <div>
      {/* <Title level={3}>Home Page</Title> */}
      <Row>
        <Col
          span={"auto"}
          style={{
            border: "2px solid rgb(0, 0, 0)",
            alignContent: "center",
            margin: "10px 10px 10px 10px",
            padding: "0px 10px 0px 10px",
          }}
        >
          <h2>Current Server Time (UTC)</h2>
          <h2>
            <Clock
              // date={"2023-04-15T00:00:00+00:00"}
              format={"DD-MM-YYYY hh:mm:ss A"}
              ticking={true}
              timezone={"UTC"}
            />
          </h2>
        </Col>
      </Row>

      <Row>
        <Col
          span={"auto"}
          style={{
            border: "2px solid rgb(0, 0, 0)",
            alignContent: "center",
            margin: "10px 10px 10px 10px",
            padding: "0px 10px 0px 10px",
          }}
        >
          <h2>Current Local Time (UTC)</h2>
          <h2>
            <Clock
              // date={"2023-04-15T00:00:00+00:00"}
              format={"DD-MM-YYYY hh:mm:ss A"}
              ticking={true}
              // timezone={"UTC"}
            />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col
          span={"auto"}
          style={{
            border: "2px solid rgb(0, 0, 0)",
            alignContent: "center",
            margin: "10px 10px 10px 10px",
            padding: "0px 10px 0px 10px",
          }}
        >
          <h2>SVS Server Time (UTC)</h2>
          <h2>
            <Clock
              date={"2023-04-15T00:00:00+00:00"}
              format={"DD-MM-YYYY hh:mm:ss A"}
              // ticking={true}
              timezone={"UTC"}
            />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col
          span={"auto"}
          style={{
            border: "2px solid rgb(0, 0, 0)",
            alignContent: "center",
            margin: "10px 10px 10px 10px",
            padding: "0px 10px 0px 10px",
          }}
        >
          <h2>Purge Time (local)</h2>
          <h2>
            <Clock
              date={"2023-04-14T22:00:00+00:00"}
              format={"DD-MM-YYYY hh:mm:ss A"}
              // ticking={true}
              // timezone={"UTC"}
            />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col
          span={"auto"}
          style={{
            border: "2px solid rgb(0, 0, 0)",
            alignContent: "center",
            margin: "10px 10px 10px 10px",
            padding: "0px 10px 0px 10px",
          }}
        >
          <h2>SVS Time (Local)</h2>
          <h2>
            <Clock
              date={"2023-04-15T00:00:00+00:00"}
              format={"DD-MM-YYYY hh:mm:ss A"}
              // ticking={true}
              // timezone={"UTC"}
            />
          </h2>
        </Col>
      </Row>
      {/* <Row>
        <Col span={12}>
          <List
            itemLayout="horizontal"
            dataSource={listData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src="https://joesch.moe/api/v1/random" />}
                  title={<a href="https://ant.design">{item.title}</a>}
                  description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                />
              </List.Item>
            )}
          />
        </Col>
        <Col span={12}>
          <Table dataSource={dataSource} columns={columns} />
        </Col>
      </Row> */}
    </div>
  );
};

export default Home;
