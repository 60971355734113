import { Calendar } from "antd";
import { Typography } from 'antd';
const {Title} =Typography;

export const CalendarPage = () => {
  return (
    <div>
      <Title level={3}>Calendar Page</Title>
      <Calendar />
    </div>
  );
};
