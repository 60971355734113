import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
const { Title, Text } = Typography;

export const SettingsPage = () => {
  const [form] = useForm();
  return (
    <div>
      <Title level={3}>User Settings</Title>
      <Form
        form={form}
        onFinish={(values) => {
          console.log(values);
        }}
      >
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Text type="secondary">On submit values will be logged</Text>
          <Card title="Personal Info">
            <Row>
              <Col span={2}>
                <FormItem
                  name={["personal", "title"]}
                  label="Title"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    options={[
                      { value: "mr", label: "Mr" },
                      { value: "ms", label: "Ms" },
                    ]}
                  />
                </FormItem>
              </Col>
              <Col offset={1} span={8}>
                <FormItem
                  name={["personal", "firstName"]}
                  label="First name"
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col offset={1} span={8}>
                <FormItem
                  name={["personal", "lastName"]}
                  label="Last name"
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={5}>
                <FormItem
                  name={["personal", "dob"]}
                  label="Date of birth"
                  labelCol={{ span: 24 }}
                >
                  <DatePicker />
                </FormItem>
              </Col>
              <Col span={4}>
                <FormItem
                  name={["personal", "numKids"]}
                  label="Number of children"
                  labelCol={{ span: 24 }}
                >
                  <InputNumber min={0} />
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Card title="Delivery Info">
            <Row>
              <Col span={8}>
                <FormItem
                  label={"Address"}
                  name={["delivery", "address"]}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col offset={1} span={6}>
                <FormItem
                  label={"City"}
                  name={["delivery", "city"]}
                  labelCol={{ span: 24 }}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col offset={1} span={6}>
                <FormItem
                  label={"Postcode"}
                  name={["delivery", "postCode"]}
                  labelCol={{ span: 24 }}
                >
                  <InputNumber min={1000} max={9999} />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={"Requested delivery time"}
                  name={["delivery", "deliveryTime"]}
                  labelCol={{ span: 24 }}
                >
                  <DatePicker showTime />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  name={["delivery", "method"]}
                  label="Delivery method"
                  labelCol={{ span: 24 }}
                >
                  <Radio.Group>
                    <Radio value={"expressPost"}>Express post</Radio>
                    <Radio value={"standardPost"}>Standard post</Radio>
                    <Radio value={"throw"}>Try to throw from here</Radio>
                  </Radio.Group>
                </FormItem>
              </Col>
            </Row>
          </Card>
          <Button
            onClick={form.submit}
            style={{ alignSelf: "right" }}
            type="primary"
          >
            Submit
          </Button>
        </Space>
      </Form>
    </div>
  );
};
